import './index.css';

const Home = () => {
  return (
    <>
      <section className="w-full h-screen xs:py-[80px] sm:py-[80px] lg:pt-[80px] lg:px-[24px] xl:px-[300px] flex flex-col justify-center items-center text-center">
        <div className="relative flex flex-col">
          <div className="font-MainTitle xs:hidden sm:hidden lg:text-[116px] leading-[128px] text-center flex z-30">
            <h1>Groundbreaking Blockchain Service Provider</h1>
          </div>
          <div className="font-MainTitle xs:text-[28px] sm:text-[32px] lg:hidden text-center xs:px-[28px] sm:px-[12px] flex flex-col">
            <h1>Groundbreaking</h1>
            <h1>Blockchain Service</h1>
            <h1>Provider</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
