import React from 'react';

const First = () => {
  return (
    <>
      <section className="w-full h-screen xs:px-[18px] sm:px-[24px] xl:px-[280px] xs:pt-[96px] sm:pt-[132px] lg:pt-[180px]">
        <div className="relative flex flex-col">
          <div className="relative flex flex-col items-center justify-center">
            <div className="text-center z-40">
              <p className="font-SubText text-white xs:hidden sm:hidden lg:text-[30px]">
                Mindshift leads the blockchain industry with various
                <br />
                attempts and innovations.
              </p>
              <p className="font-SubText text-white xs:text-[14px] sm:text-[18px] lg:hidden">
                Mindshift leads the blockchain
                <br />
                industry with various attempts and
                <br />
                innovations.
              </p>
              <p className="font-SemiTitle text-primary xs:text-[16px] sm:text-[18px] lg:text-[22px] xs:mt-[268px] sm:mt-[314px] lg:mt-[320px]">
                Virtual Asset Service Provider (VASP)
              </p>
              <p className="font-SemiText text-primary xs:hidden sm:hidden lg:text-[22px] lg:mt-[8px]">
                Mindshift is one of 36 companies (as of February 28, 2023)
                licensed for virtual asset business in
                <br /> Korea, and has a license for custody, management, and
                transfer of virtual assets.
              </p>
              <p className="font-SemiText text-primary xs:text-[12px] sm:text-[14px] lg:hidden xs:mt-[8px] sm:pt-[10px]">
                Mindshift is one of 36 companies (as of February 28, 2023)
                licensed for virtual asset business in Korea, and has a license
                for custody, management, and transfer of virtual assets.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default First;
