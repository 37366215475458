import React from 'react';
import logo from '../../assets/images/logo-bg.png';

const Header = () => {
  return (
    <header className="fixed top-0 z-50 w-full xs:h-20 sm:h-24 lg:h-32 bg-transparent xs:pt-[28px] sm:pt-[32px] lg:pt-[57px] lg:px-[120px] flex xs:justify-center sm:justify-center lg:justify-end">
      <a href="/">
        <img
          className="relative flex xs:w-[88px] xs:h-8 sm:w-[108px] sm:h-9 lg:w-48 lg:h-16"
          src={logo}
          alt=""
        />
      </a>
    </header>
  );
};

export default Header;
