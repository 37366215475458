import React, { useEffect, useRef } from 'react';
import './index.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Home from '../Home/Home';
import Zero from '../Zero/Zero';
import First from '../First/First';
import Second from '../Second/Second';
import Third from '../Third/Third';
import Fourth from '../Fourth/Fourth';
import mainWeb from '../../assets/videos/web/0-web.mp4';
import mainMobiie from '../../assets/videos/mobile/0-mobile.mp4';
import Header from '../../components/Header/Header';
// import FloatingButton from '../../components/FloatingButton/FloatingButton';
import FourthFirst from '../Fourth/FourthFirst';
import FourthSecond from '../Fourth/FourthSecond';

const Main = () => {
  const settingsMobile = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    vertical: true,
    verticalSwiping: true,
    autoplay: false,
    pauseOnFocus: true,
  };

  const settingsWeb = {
    dots: false,
    infinite: false,
    speed: 1200,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    vertical: true,
    verticalSwiping: true,
    autoplay: false,
    pauseOnFocus: true,
  };

  const webSlider = useRef(null);

  function scroll(e) {
    if (webSlider === null) return 0;

    e.wheelDelta < 0
      ? webSlider.current.slickNext()
      : webSlider.current.slickPrev();
  }

  useEffect(() => {
    window.addEventListener('wheel', scroll, true);

    return () => {
      window.removeEventListener('wheel', scroll, true);
    };
  }, []);

  return (
    <>
      {/* <FloatingButton /> */}
      <Header />
      <section className="absolute top-0 w-screen h-screen bg-black xs:py-[80px] sm:py-[80px] lg:px-[24px] lg:pt-[400px] xl:px-[300px] flex justify-center items-center">
        <div
          className="spline w-screen h-full xs:hidden sm:hidden relative flex flex-col justify-center items-center
          "
        >
          <div
            style={{
              position: 'fixed',
              top: '12vh',
              Height: '600px',
              height: '600px',
              transition: 'opacity 0.5s ease-in-out',
              zIndex: 17,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <video muted="muted" autoplay="autoplay" loop="loop" playsInline>
              <source src={mainWeb} type="video/mp4" />
            </video>
          </div>
        </div>
        <div
          className="spline w-screen h-full lg:hidden relative flex flex-col items-center justify-center
          "
        >
          <div
            style={{
              position: 'fixed',
              top: '20vh',
              height: '320px',
              width: '320px',
              transition: 'opacity 0.5s ease-in-out',
              zIndex: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <video muted="muted" autoplay="autoplay" loop="loop" playsInline>
              <source src={mainMobiie} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
      <div className="w-hull h-screen overflow-x-hidden overflow-y-auto flex justify-center items-center">
        <div className="outer h-full bg-transparent flex flex-col lg:hidden overflow-x-hidden">
          <Slider {...settingsMobile}>
            <div className="inner w-screen h-screen flex-shrink-0 z-10">
              <Home />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-10">
              <Zero />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <First />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <Second />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <Third />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <FourthFirst />
            </div>
            <div className="inner w-screen h-auto flex-shrink-0 z-[999]">
              <FourthSecond />
            </div>
          </Slider>
        </div>
        <div className="outer h-full bg-transparent flex flex-col xs:hidden sm:hidden overflow-x-hidden z-20">
          <Slider {...settingsWeb} ref={webSlider}>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <Home />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <Zero />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <First />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <Second />
            </div>
            <div className="inner w-screen h-screen flex-shrink-0 z-30">
              <Third />
            </div>
            <div className="inner w-screen h-auto flex-shrink-0 z-30">
              <Fourth />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Main;
