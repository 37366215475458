import React, { useRef, useEffect, useState } from 'react';
import secondWeb from '../../assets/videos/web/2-web.mp4';
import secondMobiie from '../../assets/videos/mobile/2-mobile.mp4';

const Second = () => {
  const [isInViewport, setIsInViewport] = useState(false);
  const [isInViewportMobile, setIsInViewportMobile] = useState(false);
  const splineRef = useRef(null);
  const splineRefMobile = useRef(null);

  const handleIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsInViewport(true);
      } else {
        setIsInViewport(false);
      }
    });
  };

  const handleIntersectionMobile = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsInViewportMobile(true);
      } else {
        setIsInViewportMobile(false);
      }
    });
  };

  // Lazy Loading - 뷰포트에 있을 때 렌더링
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (splineRef.current) {
      observer.observe(splineRef.current);
    }
    return () => {
      if (splineRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(splineRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerFirst = new IntersectionObserver(
      handleIntersectionMobile,
      options,
    );

    if (splineRefMobile.current) {
      observerFirst.observe(splineRefMobile.current);
    }
    return () => {
      if (splineRefMobile.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observerFirst.unobserve(splineRefMobile.current);
      }
    };
  }, []);

  return (
    <>
      <section className="w-full h-screen bg-black lg:px-[280px] lg:pt-[80px]  xl:px-[300px] flex justify-center items-center">
        <div className="relative flex flex-col">
          <div className="relative flex items-center xs:flex-col sm:flex-col lg:justify-between">
            <div
              className="xs:w-auto sm:w-auto lg:hidden"
              ref={splineRefMobile}
            >
              {isInViewportMobile && (
                <div
                  style={{
                    width: '220px',
                    height: '220px',
                    transition: 'opacity 0.5s ease-in-out',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <video
                    muted="muted"
                    autoplay="autoplay"
                    loop="loop"
                    playsInline
                  >
                    <source src={secondMobiie} type="video/mp4" />
                  </video>
                </div>
              )}
            </div>
            <div className="lg:w-auto xs:hidden sm:hidden" ref={splineRef}>
              {isInViewport && (
                <div
                  style={{
                    width: '410px',
                    height: '410px',
                    transition: 'opacity 0.5s ease-in-out',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <video
                    muted="muted"
                    autoplay="autoplay"
                    loop="loop"
                    playsInline
                  >
                    <source src={secondWeb} type="video/mp4" />
                  </video>
                </div>
              )}
            </div>
            <div className="lg:w-full lg:ml-[58px] bg-black xs:text-center sm:text-center lg:text-left">
              <p className="font-SubTitle text-white xs:text-[16px] sm:text-[20px] lg:text-[36px] sm:mt-[48px] xs:mt-[48px]">
                Constantly challenging
              </p>
              <p className="font-SubDetail text-primary xs:text-[12px] sm:text-[16px] lg:text-[28px] xs:mt-[24px] sm:mt-[24px] lg:mt-[28px] lg:text-left">
                Mindshift is not satisfied with the
                <br />
                present and continues to challenge.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Second;
