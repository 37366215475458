import React, { useRef, useEffect, useState } from 'react';
import './index.css';
import fourth1Mobile from '../../assets/videos/mobile/4-1-mobile.mp4';

const FourthFirst = () => {
  const [isInViewportFirst, setIsInViewportFirst] = useState(false);
  const splineRefFirst = useRef(null);

  const handleIntersectionFirst = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsInViewportFirst(true);
      } else {
        setIsInViewportFirst(false);
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerFirst = new IntersectionObserver(
      handleIntersectionFirst,
      options,
    );

    if (splineRefFirst.current) {
      observerFirst.observe(splineRefFirst.current);
    }
    return () => {
      if (splineRefFirst.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observerFirst.unobserve(splineRefFirst.current);
      }
    };
  }, []);

  const handleRadar = () => {
    window.open('https://www.radarshift.com', '_blank');
  };

  return (
    <>
      <section className="w-full h-auto bg-black flex justify-center items-center xs:pt-[156px] sm:pt-[172px]">
        <div className="relative flex flex-col">
          <div className="flex xs:flex-col sm:flex-col items-center">
            <div className="h-screen bg-black xs:pt-[20px] sm:pt-[100px] justify-center flex-shrink-0">
              <div
                className="relative flex items-center justify-center"
                ref={splineRefFirst}
              >
                {isInViewportFirst && (
                  <div
                    style={{
                      width: '140px',
                      height: '140px',
                      transition: 'opacity 0.5s ease-in-out',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <video
                      muted="muted"
                      autoplay="autoplay"
                      loop="loop"
                      playsInline
                    >
                      <source src={fourth1Mobile} type="video/mp4" />
                    </video>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center text-center xs:mt-[10px] sm:mt-[24px]">
                <p className="font-SubTitle text-white xs:text-[16px] sm:text-[20px] text-[28px] xs:mt-[28px] sm:mt-[24px]">
                  Radarshift
                </p>
                <p className="font-SubDetail text-primary xs:text-[12px] sm:text-[16px] xs:mt-[16px] sm:mt-[16px]">
                  Industry's first domestic Politically
                  <br />
                  Exposed Person (PEP) filtering service
                </p>
                <button
                  onClick={handleRadar}
                  className="grad-btn bg-black xs:w-24 xs:h-9 sm:w-24 sm:h-9 xs:rounded-[24px] sm:rounded-[24px] w-[108px] h-9  border-1 text-white xs:text-[12px] xs:mt-[24px] sm:text-[14px] sm:mt-[24px] text-[14px] mt-[52px] flex items-center justify-center"
                >
                  <div className="bg-black xs:w-[92px] xs:h-[32px] sm:w-[92px] sm:h-[32px] xs:rounded-[24px] sm:rounded-[24px] w-[104px] h-8 rounded-[42px] border-none flex justify-center items-center">
                    Explore
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FourthFirst;
